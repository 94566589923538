import React, { useEffect, useState } from "react";
import About from "./about";
import Contact from "./contact";
import LeftBar from "./side_bar";
import Resume from "./resume";
import MobileMenu from "./mobile_menu";
import Header from "./header";
import NavBar from "./nav_bar";

const Home = () => {
  const [home, setHome] = useState(true);
  const [onlyAbout, setOnlyAbout] = useState(false);
  const [onlyResume, setOnlyResume] = useState(false);
  const [onlyContact, setOnlyContact] = useState(false);

  return (
    <div id="home" className="App flex h-max bg-[#0F111A]">
      <div className="bg-[#0F111A] fixed h-full hidden lg:block">
        <LeftBar />
      </div>
      <div className="bg-[#0F111A] lg:pl-72 h-full ">
        <div className="lg:hidden">
          <MobileMenu />
        </div>
        <div className="bg-[#0F111A] h-16 w-full fixed hidden lg:block">
          <NavBar
            setHome={setHome}
            setOnlyAbout={setOnlyAbout}
            setOnlyResume={setOnlyResume}
            setOnlyContact={setOnlyContact}
          />
        </div>
        {home ? (
          <>
            <Header id="Header" />
            <About id="About" />
            <Resume id="Resume" />
            <Contact id="Contact" />{" "}
          </>
        ) : onlyAbout ? (
          <About id="About" />
        ) : onlyResume ? (
          <Resume id="Resume" />
        ) : onlyContact ? (
          <Contact id="Contact" />
        ) : null}
      </div>
    </div>
  );
};

export default Home;