import React from "react";
import { ClipboardListIcon } from "@heroicons/react/solid";

const Resume = () => {
  return (
    <div
      id="Resume"
      className="lg:w-3/4 mt-64 mb-4 text-justify mx-12 lg:mr-60 lg:ml-auto"
    >
      <div>
        <div className="table">
          <ClipboardListIcon className="h-5 w-5 mr-4 text-yellow_vs" />{" "}
          <code className="table-cell text-[#e6f1ff] text-3xl mt-5 whitespace-nowrap">
            Resume
          </code>
          <div className="table-cell border-b border-b-[#e6f1ff] border-opacity-25 w-full"></div>
        </div>
      </div>
      <div className="text-[#a2aabc] text-lg mt-5">
        <div className="flex flex-row">
            <div className="w-1/4">
              <code className="text-yellow_vs">Current Role</code>
            </div>
            <div className="w-3/4">
              <code className="italic text-[#80CBC4]">ECO Surv - Lead Developer</code>
              <br />
              <code className="text-sm text-lightblue_vs">
                December 2020 - PRESENT
              </code>
              <br />
              <code className="text-sm">
                • Line Manager to a team of 3
              </code>
              <code className="text-xs text-brown_vs">
                <br />• 1-2-1s
                <br />• Managing sickness
                <br />• Supporting the team
                <br />• Appraisals
                <br /><br />
              </code>
              <code className="text-sm">
                • Responsible for certain scrum roles such as standups and meetings and burn down reporting<br /><br />
                • Feature testing and bug fixing new features and issues that are raised by clients<br /><br />
                • Customer support and ticket raising<br /><br />
                • Data management and database administration<br /><br />
                • Design and development<br /><br />
                • Low level AWS management<br /><br />
              </code>
            </div>
        </div>
        <br />
        <div className="flex flex-row">
          <div className="w-1/4">
            <code className="text-yellow_vs">Training and <br />Education</code>
          </div>
          <div className="w-3/4">
            <code className="italic text-[#80CBC4]">Level 3 Apprenticeship</code>
            <br />
            <code className="text-sm">
              • 030: Customer Support Provision - Distinction <br /><br />
              • 033: Software Design - Distinction <br /><br />
              • 038: Event Driven Programming Using VB.NET - Distinction <br /><br />
              • 043: Software Testing - Distinction <br /><br />
              • 387: Unix Fundementals - Distinction <br /><br />
              • 389: SQL and Data Modeling - Distinction <br /><br />
              • 037: OOP programming with C# - Distinction <br /><br />
              • Functional Skills ICT - Pass <br /><br />
            </code>
            <br />
            <code className="italic text-[#80CBC4]">Level 3 Apprenticeship</code>
            <br />
            <code className="text-sm">
              • 429: System Architecture - Distinction <br /><br />
              • 421: OOP - Distinction <br /><br />
              • 409: Database Design - Distinction <br /><br />
              • 426: Software Application Testing - Pass <br /><br />
              • 415: Human Computer Interaction - Distinction <br /><br />
              • 400/401: Project Management & Effective Communication in Business - Distinction <br /><br />
              • 402: Systems Devleopment - Distinction <br /><br /><br />
            </code>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="w-1/4">
            <code className="text-yellow_vs">Attained Grades</code>
          </div>
          <div className="w-3/4">
            <code className="italic text-[#80CBC4]">8 GCSE grades A-C Including Maths and English</code>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
