import React from "react";
import { IdentificationIcon } from "@heroicons/react/solid";

const PythonLogo = require("../assets/logos/PythonLogo.png");
const ReactLogo = require("../assets/logos/ReactLogo.png");
const JsLogo = require("../assets/logos/JsLogo.png");
const TailwindLogo = require("../assets/logos/TailwindLogo.png");
const SqlLogo = require("../assets/logos/SqlLogo.png");
const MongoDBLogo = require("../assets/logos/MongoDBLogo.png");
const NodeJSLogo = require("../assets/logos/NodeJSLogo.png");
const DockerLogo = require("../assets/logos/DockerLogo.png");
const RubyLogo = require("../assets/logos/ruby.png");
const phpLogo = require("../assets/logos/php.png");
const GolangLogo = require("../assets/logos/golang.png");
const awsLogo = require("../assets/logos/aws.png");
const html5Logo = require("../assets/logos/html5.png");
const GraphqlLogo = require("../assets/logos/graphql.png");
const LinuxLogo = require("../assets/logos/linux.png");

const About = () => {
  return (
    <div
      id="About"
      className="mx-12 mt-60 lg:mx-60 items-center justify-center lg:w-1/2 text-justify"
    >
      <div>
        <div className="table">
          <IdentificationIcon className="h-5 w-5 mr-4 text-yellow_vs" />{" "}
          <code className="table-cell text-[#e6f1ff] text-3xl mt-5 whitespace-nowrap">
            About Me
          </code>
          <div className="table-cell border-b border-b-[#e6f1ff] border-opacity-25 w-full"></div>
        </div>
        <div className="text-[#a2aabc] text-lg mt-5">
          <code>
            I am currently a Lead Developer. A vast majority of my time was spent on a legacy project that is in a sunset period however Since January I have migrated onto developing a 
            new version of that same product. My current work tasks contain over looking the work proposed and carried out on the legacy product as well as contributing with the MVP workload.
            <br /><br />
            The legacy System is a vanilla PHP system with Boostrap HTML frontend. I have worked on the system for the majority of my time with my current
            company and have worked my way up from an apprentice to a lead. My understanding and knowledge of the company The legacy system means I am heavily involved
            in the design and implementation of the work. I manage a team of 3 who maintained the legacy system and while 2 of us have migrated to the MVP project I still
            keep an eye on the other guys and make sure I have time for any questions or guidance and direct them if necessary.
            <br /><br />
            The MVP system is a React/Laravel system and my main focus has been on the back-end, implementing a mix of REST and GLQ endpoints. I have also worked on
            implementing JSON schemas to drive forms in the system. The database for both systems is mariaDB so I am experienced with SQL. In addition to the laravel development i also
            developed a ruby lambda function for pdf generation, that receives JSON objects of pdf data and pdf structure and builds and returns a pdf file.
            <br /><br />
            I have experience with AWS, managing codepipelines for staging environment as well as working with RDS and EC2. I have also used monitoring services for the live
            platform such as New Relic. For a short period of time we also looked at implementing Golang so I also spent some time learning Golang however this isn’t something
            I have been exposed to for a long time. I have great communicative skills and understand there are different ways to convey a message to team members. Problem solving
            is key to being a developer and it is something I believe that I thrive in delivering with my team.
            <br /><br />
            I have also taken on a SCRUM master role, although not my full time role, i lead standups meetings in a morning helping understand where the team is at with the delivery 
            and accelerate solutions for any blocks or issue that have dependancies external to the development team due. Additionally i am involved with process changes within the team as well for 
            reporting and prepping for retrospectives as well as forward planning features and development work.
          </code>
          <br />
          <br />
          <div className="grid grid-cols-3 gap-2 text-sm mt-5 text-brown_vs">
            <div className="flex flex-row  mb-3">
              <img
                src={JsLogo}
                alt="Js Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>JavaScript (ES6+)</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={ReactLogo}
                alt="React Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>React</code>
            </div>
            <div className="flex flex-row  mb-3">
              <img
                src={TailwindLogo}
                alt="Tailwind Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Tailwind CSS</code>
            </div>
            <div className="flex flex-row  mb-3">
              <img
                src={PythonLogo}
                alt="Python Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Python</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={NodeJSLogo}
                alt="NodeJS Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Nodejs</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={SqlLogo}
                alt="SQL Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>SQL</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={MongoDBLogo}
                alt="MongoDB Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>MongoDB</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={DockerLogo}
                alt="Docker Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Docker</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={RubyLogo}
                alt="Ruby Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Ruby</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={phpLogo}
                alt="php Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>PHP</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={awsLogo}
                alt="aws Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>AWS</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={GolangLogo}
                alt="go Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Golang</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={html5Logo}
                alt="html Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>HTML5</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={LinuxLogo}
                alt="linux Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>Linux</code>
            </div>
            <div className="flex flex-row mb-3">
              <img
                src={GraphqlLogo}
                alt="graphql Logo"
                className="h-5 w-5 ml-3 mr-4 text-yellow_vs"
              />
              <code>GraphQL</code>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="table">
          <IdentificationIcon className="h-5 w-5 mr-4 text-yellow_vs" />{" "}
          <code className="table-cell text-[#e6f1ff] text-3xl mt-5 whitespace-nowrap">
            Interests and Hobbies
          </code>
          <div className="table-cell border-b border-b-[#e6f1ff] border-opacity-25 w-full"></div>
        </div>
        <div className="text-[#a2aabc] text-lg mt-5">
          <code>
            Football is one of my main hobbies. I have a season ticket to watch Liverpool and play 7 aside every week.
            Technology is another hobby of mine. I try and push myself in my own time to learn more about languages I currently learn as well as independently learn
            new skills and languages for personal use, and for me to progress in my profession and push towards a position that I strive to be in in the future. I have
            created projects with python such as scrapers using beautiful soup and selenium as well as using algorithms such as a* to create pathfinders.
            <br /><br />
            I Enjoy looking at general purpose languages such as python and ruby and applying them to different scenarios such as scripts or using them in Web development.
            I have also been researching into full javascript web stacks such as MERN and this has lead me to look at Node.js and Express which I have developed a rest
            API with.
            <br /><br />
            When I first began looking into software I invested in a Raspberry Pi, which I self-taught how to build projects using content from the internet
            and magazines. I created a retro gaming system from the Raspberry Pi. I also put a DAB radio on my Pi and used it as a digital photo library. This shows that I
            like to self-educate and that I like to complete a challenge even when I have no prior knowledge. This interest in hardware progressed further, eventually leading
            me to build my own PC.
          </code>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default About;
